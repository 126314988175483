import Booking from '../interfaces/Booking';
import TimeSlot from '../interfaces/TimeSlot';
import RegisterForm from '../interfaces/RegisterForm';
import Guest from '../interfaces/Guest';
import AdditionalGuest from '../interfaces/AdditionalGuest';
import GuestTimeSlot from '../interfaces/GuestTimeSlot';
import { ApiError } from '../../errors/utils';
import { BookingLanguageResponse, RegisterResponse } from './actions';
import MasterData from '../interfaces/MasterData';
import { AccessMethod } from '../interfaces/AccessMethod';
import WalletPass from '../interfaces/WalletPass';
import EmailVerification from '../interfaces/EmailVerification';
import { AvailableTimeSlotInterface } from '@/features/booking/interfaces/TimeSlotAvailability';

/**
 * Get a booking
 */

export const REQUEST_FETCH_BOOKING = 'REQUEST_FETCH_BOOKING';
export const REQUEST_FETCH_BOOKING_FINISHED = 'REQUEST_FETCH_BOOKING_FINISHED';

interface RequestFetchBookingAction {
  type: typeof REQUEST_FETCH_BOOKING;
}

interface RequestFetchBookingFinishedAction {
  type: typeof REQUEST_FETCH_BOOKING_FINISHED;
  payload?: Booking | ApiError;
  error?: boolean;
}

/**
 * Get a booking's language
 */

export const REQUEST_FETCH_BOOKING_LANGUAGE = 'REQUEST_FETCH_BOOKING_LANGUAGE';
export const REQUEST_FETCH_BOOKING_LANGUAGE_FINISHED = 'REQUEST_FETCH_BOOKING_LANGUAGE_FINISHED';

interface RequestFetchBookingLanguageAction {
  type: typeof REQUEST_FETCH_BOOKING_LANGUAGE;
}

interface RequestFetchBookingLanguageFinishedAction {
  type: typeof REQUEST_FETCH_BOOKING_LANGUAGE_FINISHED;
  payload?: BookingLanguageResponse | ApiError;
  error?: boolean;
}

/**
 * Get the available bookable time slots (within a time range of 1 week)
 */

export const REQUEST_FETCH_TIMESLOTS = 'REQUEST_FETCH_TIMESLOTS';
export const REQUEST_FETCH_TIMESLOTS_FINISHED = 'REQUEST_FETCH_TIMESLOTS_FINISHED';

interface RequestFetchTimeSlotsAction {
  type: typeof REQUEST_FETCH_TIMESLOTS;
}

interface RequestFetchTimeSlotsFinishedAction {
  type: typeof REQUEST_FETCH_TIMESLOTS_FINISHED;
  payload?: AvailableTimeSlotInterface | ApiError;
  error?: boolean;
}

/**
 * Get the available rsvp bookable time slot
 */

export const REQUEST_FETCH_RSVP_TIMESLOT = 'REQUEST_FETCH_RSVP_TIMESLOT';
export const REQUEST_FETCH_RSVP_TIMESLOT_FINISHED = 'REQUEST_FETCH_RSVP_TIMESLOT_FINISHED';

interface RequestFetchRsvpTimeSlotAction {
  type: typeof REQUEST_FETCH_RSVP_TIMESLOT;
}

interface RequestFetchRsvpTimeSlotFinishedAction {
  type: typeof REQUEST_FETCH_RSVP_TIMESLOT_FINISHED;
  payload?: TimeSlot | ApiError;
  error?: boolean;
}

/**
 * Check if bookable time slots are available
 */

export const REQUEST_FETCH_AVAILABILITIES = 'REQUEST_FETCH_AVAILABILITIES';
export const REQUEST_FETCH_AVAILABILITIES_FINISHED = 'REQUEST_FETCH_AVAILABILITIES_FINISHED';

interface RequestFetchAvailabilitiesAction {
  type: typeof REQUEST_FETCH_AVAILABILITIES;
}

interface RequestFetchAvailabilitiesFinishedAction {
  type: typeof REQUEST_FETCH_AVAILABILITIES_FINISHED;
  payload?: boolean | ApiError;
  error?: boolean;
}

/**
 * Register (book date & time)
 */

export const REQUEST_BOOKING_REGISTER = 'REQUEST_BOOKING_REGISTER';
export const REQUEST_BOOKING_REGISTER_FINISHED = 'REQUEST_BOOKING_REGISTER_FINISHED';

interface RequestBookingRegisterAction {
  type: typeof REQUEST_BOOKING_REGISTER;
}

interface RequestBookingRegisterFinishedAction {
  type: typeof REQUEST_BOOKING_REGISTER_FINISHED;
  payload?: RegisterResponse | ApiError;
  error?: boolean;
}

/**
 * Change booked time slot (date/time and/or additional guests)
 */

export const REQUEST_BOOKING_CHANGE = 'REQUEST_BOOKING_CHANGE';
export const REQUEST_BOOKING_CHANGE_FINISHED = 'REQUEST_BOOKING_CHANGE_FINISHED';

interface RequestBookingChangeAction {
  type: typeof REQUEST_BOOKING_CHANGE;
}

interface RequestBookingChangeFinishedAction {
  type: typeof REQUEST_BOOKING_CHANGE_FINISHED;
  payload?: boolean | ApiError;
  error?: boolean;
}

/**
 * Validate captcha
 */

export const REQUEST_BOOKING_CAPTCHA_VALIDATION = 'REQUEST_BOOKING_CAPTCHA_VALIDATION';
export const REQUEST_BOOKING_CAPTCHA_VALIDATION_FINISHED = 'REQUEST_BOOKING_CAPTCHA_VALIDATION_FINISHED';

interface RequestBookingCaptchaValidationAction {
  type: typeof REQUEST_BOOKING_CAPTCHA_VALIDATION;
}

interface RequestBookingCaptchaValidationFinishedAction {
  type: typeof REQUEST_BOOKING_CAPTCHA_VALIDATION_FINISHED;
  payload?: boolean;
  error?: boolean;
}

/**
 * Booking registration
 */

export const BOOKING_REGISTRATION_SET_TIMESLOTS = 'BOOKING_REGISTRATION_SET_TIMESLOTS';

interface BookingRegistrationSetTimeslotsAction {
  type: typeof BOOKING_REGISTRATION_SET_TIMESLOTS;
  payload: TimeSlot[];
}

export const BOOKING_REGISTRATION_SET_FORMDATA = 'BOOKING_REGISTRATION_SET_FORMDATA';

interface BookingRegistrationSetFormDataAction {
  type: typeof BOOKING_REGISTRATION_SET_FORMDATA;
  payload: RegisterForm;
}

/**
 * Booking target
 */

export const BOOKING_SET_TARGET = 'BOOKING_SET_TARGET';

interface BookingSetTargetAction {
  type: typeof BOOKING_SET_TARGET;
  payload: string;
}

/**
 * Get a guest
 */

export const REQUEST_FETCH_GUEST = 'REQUEST_FETCH_GUEST';
export const REQUEST_FETCH_GUEST_FINISHED = 'REQUEST_FETCH_GUEST_FINISHED';

interface RequestFetchGuestAction {
  type: typeof REQUEST_FETCH_GUEST;
}

interface RequestFetchGuestFinishedAction {
  type: typeof REQUEST_FETCH_GUEST_FINISHED;
  payload?: Guest | ApiError;
  error?: boolean;
}

/**
 * Cancel a booking of a specific time slot
 */

export const REQUEST_CANCEL_TIME_SLOT_BOOKING = 'REQUEST_CANCEL_TIME_SLOT_BOOKING';
export const REQUEST_CANCEL_TIME_SLOT_BOOKING_FINISHED = 'REQUEST_CANCEL_TIME_SLOT_BOOKING_FINISHED';

interface RequestCancelTimeSlotBookingAction {
  type: typeof REQUEST_CANCEL_TIME_SLOT_BOOKING;
}

interface RequestCancelTimeSlotBookingFinishedAction {
  type: typeof REQUEST_CANCEL_TIME_SLOT_BOOKING_FINISHED;
  payload?: Guest | ApiError;
  error?: boolean;
}

/**
 * Delete guest account
 */

export const REQUEST_DELETE_GUEST_ACCOUNT = 'REQUEST_DELETE_GUEST_ACCOUNT';
export const REQUEST_DELETE_GUEST_ACCOUNT_FINISHED = 'REQUEST_DELETE_GUEST_ACCOUNT_FINISHED';

interface RequestDeleteGuestAccountAction {
  type: typeof REQUEST_DELETE_GUEST_ACCOUNT;
}

interface RequestDeleteGuestAccountFinishedAction {
  type: typeof REQUEST_DELETE_GUEST_ACCOUNT_FINISHED;
  payload?: string | ApiError;
  error?: boolean;
}

/**
 * Edit a time slot
 */

export const BOOKING_SET_TIMESLOT_TO_EDIT = 'BOOKING_SET_TIMESLOT_TO_EDIT';

interface BookingSetTimeSlotToEditAction {
  type: typeof BOOKING_SET_TIMESLOT_TO_EDIT;
  payload?: GuestTimeSlot;
}

/**
 * Get an ICS file for a given time slot
 */

export const REQUEST_FETCH_TIME_SLOT_ICS = 'REQUEST_FETCH_TIME_SLOT_ICS';
export const REQUEST_FETCH_TIME_SLOT_ICS_FINISHED = 'REQUEST_FETCH_TIME_SLOT_ICS_FINISHED';

interface RequestFetchTimeSlotIcsAction {
  type: typeof REQUEST_FETCH_TIME_SLOT_ICS;
}

interface RequestFetchTimeSlotIcsFinishedAction {
  type: typeof REQUEST_FETCH_TIME_SLOT_ICS_FINISHED;
  payload?: string | ApiError;
  error?: boolean;
}

export const RESET_TIME_SLOT_ICS = 'RESET_TIME_SLOT_ICS';

interface ResetTimeSlotIcsAction {
  type: typeof RESET_TIME_SLOT_ICS;
}

/**
 * Reset registration state
 */

export const RESET_REGISTRATION = 'RESET_REGISTRATION';

interface ResetRegistrationAction {
  type: typeof RESET_REGISTRATION;
  payload: {
    clearFormData: boolean;
  };
}

/**
 * Set additional guests
 */

export const BOOKING_REGISTRATION_SET_ADDITIONAL_GUESTS = 'BOOKING_REGISTRATION_SET_ADDITIONAL_GUESTS';

interface BookingRegistrationSetAdditionalGuestsAction {
  type: typeof BOOKING_REGISTRATION_SET_ADDITIONAL_GUESTS;
  payload: AdditionalGuest[];
}

/**
 * Fetch master data
 */

export const REQUEST_FETCH_MASTERDATA = 'REQUEST_FETCH_MASTERDATA';
export const REQUEST_FETCH_MASTERDATA_FINISHED = 'REQUEST_FETCH_MASTERDATA_FINISHED';

interface RequestFetchMasterDataAction {
  type: typeof REQUEST_FETCH_MASTERDATA;
}

interface RequestFetchMasterDataFinishedAction {
  type: typeof REQUEST_FETCH_MASTERDATA_FINISHED;
  payload?: MasterData | ApiError;
  error?: boolean;
}

/**
 * Get access method of the given target
 */

export const REQUEST_FETCH_ACCESS_METHOD = 'REQUEST_FETCH_ACCESS_METHOD';
export const REQUEST_FETCH_ACCESS_METHOD_FINISHED = 'REQUEST_FETCH_ACCESS_METHOD_FINISHED';

interface RequestFetchAccessMethodAction {
  type: typeof REQUEST_FETCH_ACCESS_METHOD;
}

interface RequestFetchAccessMethodFinishedAction {
  type: typeof REQUEST_FETCH_ACCESS_METHOD_FINISHED;
  payload?: AccessMethod | ApiError;
  error?: boolean;
}

/**
 * Check target's access password
 */

export const REQUEST_CHECK_TARGET_PASSWORD = 'REQUEST_CHECK_TARGET_PASSWORD';
export const REQUEST_CHECK_TARGET_PASSWORD_FINISHED = 'REQUEST_CHECK_TARGET_PASSWORD_FINISHED';

interface RequestCheckTargetPasswordAction {
  type: typeof REQUEST_CHECK_TARGET_PASSWORD;
}

interface RequestCheckTargetPasswordFinishedAction {
  type: typeof REQUEST_CHECK_TARGET_PASSWORD_FINISHED;
  payload?: boolean | ApiError;
  error?: boolean;
}

/**
 * Set target's access token or password
 */

export const BOOKING_SET_TARGET_ACCESS_PASSWORD = 'BOOKING_SET_TARGET_ACCESS_PASSWORD';

interface BookingSetTargetAccessPasswordAction {
  type: typeof BOOKING_SET_TARGET_ACCESS_PASSWORD;
  payload: string;
}

/**
 * Email is a duplicate and needs to be verified state
 */

export const BOOKING_SET_EMAIL_DUPLICATE_VERIFICATION = 'BOOKING_SET_EMAIL_DUPLICATE_VERIFICATION';

interface BookingSetEmailDuplicateVerificationAction {
  type: typeof BOOKING_SET_EMAIL_DUPLICATE_VERIFICATION;
  payload: EmailVerification;
}

/**
 * Set the visibility of the wallet pass dialog
 */

export const BOOKING_SET_WALLET_PASS_DIALOG_VISIBILITY = 'BOOKING_SET_WALLET_PASS_DIALOG_VISIBILITY';

interface BookingSetTWalletPassDialogVisibilityAction {
  type: typeof BOOKING_SET_WALLET_PASS_DIALOG_VISIBILITY;
  payload: {
    isVisible: boolean;
    selection?: TimeSlot;
  };
}

/**
 * Get a Wallet pass file
 */

export const REQUEST_FETCH_WALLET_PASS = 'REQUEST_FETCH_WALLET_PASS';
export const REQUEST_FETCH_WALLET_PASS_FINISHED = 'REQUEST_FETCH_WALLET_PASS_FINISHED';

interface RequestFetchWalletPassAction {
  type: typeof REQUEST_FETCH_WALLET_PASS;
}

interface RequestFetchWalletPassFinishedAction {
  type: typeof REQUEST_FETCH_WALLET_PASS_FINISHED;
  payload?: WalletPass | ApiError;
  error?: boolean;
}

export const RESET_WALLET_PASS = 'RESET_WALLET_PASS';

interface ResetWalletPassAction {
  type: typeof RESET_WALLET_PASS;
}

/**
 * Reset all state
 */

export const RESET_ALL = 'RESET_ALL';

interface ResetAllAction {
  type: typeof RESET_ALL;
}

/**
 * Redux State
 */

export interface BookingState {
  hasAvailabilities: boolean | null;
  booking: Booking | null;
  /**
   * Available time slots within the selected date range
   */
  timeSlots: AvailableTimeSlotInterface;
  rsvpTimeslot: TimeSlot | null;
  guest: Guest | null;
  registration: {
    /**
     * Time slots selected by the user (ready to be booked)
     */
    timeSlots: TimeSlot[];
    formData: RegisterForm;
    additionalGuests: AdditionalGuest[];
    confirmed: boolean;
    changeConfirmed: boolean;
    createdGuestUuid: string;
    deletedGuestUuid: string;
  };
  captchaValidated: boolean;
  currentTarget: string;
  timeSlotToEdit: GuestTimeSlot | null;
  timeSlotCancelled: boolean;
  ics: string;
  walletPass: WalletPass;
  walletPassDialogVisible: boolean;
  walletPassDialogSelection: TimeSlot | null;
  masterData: MasterData;
  /**
   * Access method configured for the current booking's target
   */
  accessMethod: AccessMethod | null;
  /**
   * The "password" can be a clear text password (if target's accessMethod is set to "password") OR an OAuth 2.0 access
   * token (if target's accessMethod is set to "collaborators").
   * Needed to do subsequent requests to booking's endpoints.
   */
  accessPassword: string;
  /**
   * Set to TRUE is the password is valid (for OAuth 2.0 access token, it will also validate user's permissions)
   */
  accessPasswordValid: boolean | null;
  /**
   * For boutique's booking manage the duplicate email and verification
   */
  emailVerification: EmailVerification;
}

/**
 * Redux Actions Types
 */

export type BookingActionTypes =
  | RequestFetchBookingAction
  | RequestFetchBookingFinishedAction
  | RequestFetchBookingLanguageAction
  | RequestFetchBookingLanguageFinishedAction
  | RequestFetchTimeSlotsAction
  | RequestFetchTimeSlotsFinishedAction
  | RequestFetchRsvpTimeSlotAction
  | RequestFetchRsvpTimeSlotFinishedAction
  | RequestFetchAvailabilitiesAction
  | RequestFetchAvailabilitiesFinishedAction
  | RequestBookingRegisterAction
  | RequestBookingRegisterFinishedAction
  | RequestBookingChangeAction
  | RequestBookingChangeFinishedAction
  | RequestBookingCaptchaValidationAction
  | RequestBookingCaptchaValidationFinishedAction
  | BookingRegistrationSetTimeslotsAction
  | BookingRegistrationSetFormDataAction
  | BookingRegistrationSetAdditionalGuestsAction
  | BookingSetTargetAction
  | BookingSetTWalletPassDialogVisibilityAction
  | BookingSetEmailDuplicateVerificationAction
  | RequestFetchGuestAction
  | RequestFetchGuestFinishedAction
  | RequestCancelTimeSlotBookingAction
  | RequestCancelTimeSlotBookingFinishedAction
  | RequestFetchTimeSlotIcsAction
  | RequestFetchTimeSlotIcsFinishedAction
  | ResetTimeSlotIcsAction
  | BookingSetTimeSlotToEditAction
  | ResetRegistrationAction
  | RequestDeleteGuestAccountAction
  | RequestDeleteGuestAccountFinishedAction
  | RequestFetchMasterDataAction
  | RequestFetchMasterDataFinishedAction
  | RequestFetchAccessMethodAction
  | RequestFetchAccessMethodFinishedAction
  | RequestCheckTargetPasswordAction
  | RequestCheckTargetPasswordFinishedAction
  | BookingSetTargetAccessPasswordAction
  | RequestFetchWalletPassAction
  | RequestFetchWalletPassFinishedAction
  | ResetWalletPassAction
  | ResetAllAction;
