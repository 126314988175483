import { lazy } from 'react';
import { BASE_URL, EXPIRED_PATH } from './uri';

const Validate = lazy(() => import('../pages/ValidatePage'));
const Expired = lazy(() => import('../pages/ExpiredPage'));

const routes = [
  {
    path: `${BASE_URL}/:target/:guest`,
    element: <Validate />,
  },
  {
    path: `${BASE_URL}/${EXPIRED_PATH}`,
    element: <Expired />,
  },
];

export default routes;
